:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;

  --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
  --secondary-glow: linear-gradient(
    to bottom right,
    rgba(1, 65, 255, 0),
    rgba(1, 65, 255, 0),
    rgba(1, 65, 255, 0.3)
  );

  --tile-start-rgb: 2, 13, 46;
  --tile-end-rgb: 2, 5, 19;
  --tile-border: conic-gradient(
    #ffffff80,
    #ffffff40,
    #ffffff30,
    #ffffff20,
    #ffffff10,
    #ffffff10,
    #ffffff80
  );

  --callout-rgb: 20, 20, 20;
  --callout-border-rgb: 108, 108, 108;
  --card-rgb: 100, 100, 100;
  --card-border-rgb: 200, 200, 200;
}

/* GLOBALS */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
